import createStore from '@/store'
export default function log({ next, to }) {
  // let haveAccess = false
  Promise.all([dataUser()])
  .then(rutas => {
    if(!rutas[0]?.includes(to.name))
      return next('/')
  })
  return next();
}
async function dataUser() {
  let usuario = null
  await createStore().then(store => {
    if(!usuario)
      usuario = store.getters.user.rutas

  })
  return usuario
}