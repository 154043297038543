// import publicAxios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import createMutationsSharer from "vuex-shared-mutations"
import { db, storage } from '@/plugins/firebase'
import {collection, doc, getDoc, updateDoc, getDocs, query, where, serverTimestamp } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage";


Vue.use(Vuex)

export default async function () {
  
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
  const usuario = localStorage.getItem('usuario') ? localStorage.getItem('usuario') : null
  const negocio = localStorage.getItem('negocio') ? localStorage.getItem('negocio') : null
  const logo = localStorage.getItem('logo') ? localStorage.getItem('logo') : null
  
  const docRef = doc(db, "negocio", '00001')
  const negocioData = await getDoc(docRef)
  
  let logoUrl =  null
  await getDownloadURL(ref(storage, "base/00001_480x320.webp"))
  .then((url) => {
    logoUrl = url
  })
  .catch(()=>{
    logoUrl =  null
  })
  
  const getDefaultState = () => {
    return {
      token: token,
      usuario: usuario,
      negocio: negocio,
      logo: logo
    }
  }

  const store = new Vuex.Store({
    state: getDefaultState,
    mutations: {
      setLogo (state, logo) {
        state.logo = logo
      },
      setNegocio (state, negocio) {
        state.negocio = negocio
      },
      setUsuario (state, usuario) {
        state.usuario = usuario
        // console.log('nuevos datos ', usuario)
      },
      setToken (state, token) {
        state.token = token
      },
      RESET_STATE(state) {
        state.token = null
        state.usuario = null
        // state.negocio = null
      },
      updateData (state, dataDetails) {
        state.usuario.nombre = dataDetails.nombre
        state.usuario.avatar = dataDetails.avatar
      },
    },
    actions: {
      async setInfoNegocio ({ commit }, { dataDetails }) {
        const docRef = doc(db, "negocio", '00001')
        updateDoc(docRef, dataDetails) 
        commit("setNegocio", dataDetails)
        localStorage.setItem('negocio', negocio)
      },
      async setDataCajaReload ({ commit }, { data }) {
        commit("setUsuario", data)
        localStorage.setItem('usuario', usuario)
      },
      async setInfoUser ({ commit }, { data }) {
        const docRef = doc(db, 'users', data.uid)
        updateDoc(docRef, {
          aperturaCaja: data.aperturaCaja,
          fechaCaja: data.aperturaCaja ? new serverTimestamp : null,
          cajaId: data.cajaId
        })
        console.log('store -- ACTUALIZA CAJA - ', data)
        commit("setUsuario", data)
        localStorage.setItem('usuario', usuario)
      },
      async login ({ commit }, { authDetails }) {
        const token = authDetails.uid
        const usuario = authDetails

        const rolQuery = query(collection(db, 'roles'), where("rol", "==", usuario.rol))
        const roles = await getDocs(rolQuery)
        usuario['rutas'] = []
        roles.forEach((rol) => {
          usuario['rutas'] = rol.data().rutas
        })

        commit("setUsuario", usuario)
        commit("setToken", token)
        localStorage.setItem('token', token)
        localStorage.setItem('usuario', usuario)
      },
      async updateProfile ({ commit }, { dataDetails }) {
        commit("updateData", dataDetails)
        localStorage.setItem('usuario', (state) => { return state.usuario} )
      },
      logout ({ commit }) {
        commit("setToken", null)
        commit("setUsuario", null)
        commit("RESET_STATE")
        localStorage.clear()
      }
    },
    getters: {
      aperturaCaja: (state) => {
        return state.usuario && state.usuario.aperturaCaja ? state.usuario.aperturaCaja : false
      },
      userId: (state) => {
        return state.usuario && state.usuario.uid ? state.usuario.uid : null
      },
      cajaId: (state) => {
        return state.usuario && state.usuario.cajaId ? state.usuario.cajaId : null
      },
      userName: (state) => {
        return state.usuario && state.usuario.nombre ? state.usuario.nombre : null
      },
      userRol: (state) => {
        return state.usuario && state.usuario.rol ? state.usuario.rol : null
      },
      user: (state) => {
        return state.usuario ? state.usuario : null
      },
      logeado: (state) => {
        return state.token ?  true : false
      },
      negocio: (state) => {
        return state.negocio ? state.negocio : null
      },
      logo: (state) => {
        return state.logo ? state.logo : null
      }
    },
    plugins: [
      createPersistedState({
        storage: localStorage
      },
      createMutationsSharer({
        predicate: (mutation) => {
          const predicate = [
            "setLogo",
            "setNegocio",
            "setUsuario",
            "setToken",
            "RESET_STATE",
            "updateData"
          ]
          return predicate.indexOf(mutation.type) >= 0
        }
      })
    )]
  })

  if (negocioData && negocioData.exists()) {
    store.commit('setNegocio', negocioData.data())
  }
  
  if (logoUrl && logoUrl.length) {
    store.commit('setLogo', logoUrl)
  }

  return store
}
